import { useGetList, useRecordContext } from 'react-admin';
import { Club } from 'src/admin-api-types';
import { StatsBundle } from 'src/common/components/StatsBundle';

import { StatsBundleSkeleton } from '../skeletons/StatsBundleSkeleton';

export const ClubMembershipListStats = () => {
  const record = useRecordContext<Club>();
  const clubId = record?.id;
  const url = `${clubId?.substring(1)}/registrations`;

  const { data, isLoading, error } = useGetList(url);

  if (isLoading) {
    return <StatsBundleSkeleton />;
  }
  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return <StatsBundle data={data ?? []} />;
};
