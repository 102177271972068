/**
 * Sentry configuration factory function
 */
export const getSentryConfig = (dsn: string, environment: string) => {
  const isProduction = environment === 'production';

  return {
    dsn,
    environment,
    debug: !isProduction,
    tracesSampleRate: 0.2,
    integrations: [],
  };
};
