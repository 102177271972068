import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import App from './app';
import initSentry from './config/sentry/initSentry';
import setUserInSentry from './config/sentry/setUserInSentry';
import { SettingsProvider } from './domains/system/settings';

initSentry();

setUserInSentry();

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Suspense>
        <SettingsProvider>
          <App />
        </SettingsProvider>
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>
);
