export enum ClubMembershipSourceEnum {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  COMPANY = 'organizationName',
  EMAIL = 'email',
  PHONE = 'phoneNumber',
  CLUB_NAME = 'clubName',
  STATUS = 'status',
  DEPARTMENT = 'clubDepartment',
  MODIFY_AT = 'updatedAt',
}
