import { Box, Card, Stack } from '@mui/material';
import { t } from 'i18next';
import { SaveButton, SimpleForm, useRecordContext } from 'react-admin';
import { Organization } from 'src/admin-api-types';
import { OrganizationSourceEnum } from 'src/common/enums/react-admin';
import { ValidationError } from 'src/domains/club/contract/context';
import {
  validateName,
  validateRna,
  validateSiret,
} from 'src/domains/club/members/detail/validators';
import { tokens } from 'src/locales/tokens';

import { OrganizationCardEditContentSkeleton } from '../../skeletons';
import { CardTitle } from '../common/CardTitle';
import { ConcatAddressLabel } from '../fields/ConcatAddressLabel';
import { CustomInputLabel } from '../fields/CustomInputLabel';
import { CustomTextLabel } from '../fields/CustomTextLabel';
import { FormattedNumberLabel } from '../fields/FormattedNumberWithLabel';

interface OrganizationDetailContentProps {
  readonly?: boolean;
}

export const OrganizationDetailContent: React.FC<OrganizationDetailContentProps> = ({
  readonly,
}) => {
  const record = useRecordContext<Organization>();

  if (!record) {
    return <OrganizationCardEditContentSkeleton />;
  }

  const validateForm = (record: Organization) => (values: Organization) => {
    const errors: ValidationError = {};
    validateSiret(record, values, errors);
    validateName(values, errors);
    validateRna(values, errors);

    return errors;
  };

  return (
    <Card
      sx={{
        flexGrow: 1,
      }}
    >
      <CardTitle text={t(tokens.domains.members.detail.organizationCardTitle)} />
      <SimpleForm
        toolbar={false}
        sx={{
          padding: readonly ? '0px 24px 30px 24px' : '0px 24px !important',
        }}
        validate={validateForm(record)}
      >
        <Stack
          direction={'column'}
          spacing={4}
          width={'100%'}
        >
          <Stack
            direction={'row'}
            spacing={3}
          >
            <CustomTextLabel
              source={OrganizationSourceEnum.brand}
              label={t(tokens.domains.members.detail.label.brand)}
            />
            <CustomInputLabel
              source={'name'}
              label={t(tokens.domains.members.detail.label.corporateName)}
              disabled={readonly}
            />
            <CustomTextLabel
              source={OrganizationSourceEnum.activity}
              label={t(tokens.domains.members.detail.label.activity)}
            />
          </Stack>
          <Stack
            direction={'row'}
            spacing={3}
          >
            <ConcatAddressLabel label={t(tokens.domains.members.detail.label.fullAddress)} />
            <CustomInputLabel
              source={OrganizationSourceEnum.businessIdentifier}
              label={t(tokens.domains.members.detail.label.siret)}
              disabled={readonly}
            />
            <CustomTextLabel
              link
              source={OrganizationSourceEnum.website}
              value={record?.[OrganizationSourceEnum.website] || '-'}
              label={t(tokens.domains.members.detail.label.website)}
            />
          </Stack>
          <Stack
            direction={'row'}
            spacing={3}
          >
            <FormattedNumberLabel
              source={OrganizationSourceEnum.capital}
              label={t(tokens.domains.members.detail.label.capital)}
              currency="€"
            />
            <CustomInputLabel
              source={OrganizationSourceEnum.associationIdentifier}
              label={t(tokens.domains.members.detail.label.rna)}
              disabled={readonly}
            />
            <FormattedNumberLabel
              source={OrganizationSourceEnum.size}
              label={t(tokens.domains.members.detail.label.size)}
            />
          </Stack>
        </Stack>
        {!readonly && (
          <Box
            display={'flex'}
            justifyContent={'end'}
            width={'100%'}
            margin="24px 0px"
          >
            <SaveButton
              label={t(tokens.common.button.validate)}
              type="submit"
              icon={<></>}
            />
          </Box>
        )}
      </SimpleForm>
    </Card>
  );
};
