import {
  ACCESS_TOKEN_STORAGE_KEY,
  REFRESH_TOKEN_STORAGE_KEY,
} from 'src/contexts/auth/jwt/AuthProvider';
import { ENTRYPOINT } from 'src/data/provider/remote/dataProvider';

export interface RefreshTokenResponse {
  token: string;
  refresh_token: string;
}

/**
 * Executes the refresh token request and updates the local storage with the new token.
 *
 * @returns {Promise<string>} A promise that resolves to the new access token.
 * @throws Will throw an error if the token refresh fails or no refresh token is available.
 */
export const executeRefreshToken = async (): Promise<string> => {
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY);
  if (!refreshToken) {
    throw new Error('No refresh token available');
  }

  const request = new Request(ENTRYPOINT + '/auth/token/refresh', {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({ refresh_token: refreshToken }),
  });

  const response = await fetch(request);
  if (!response.ok) {
    throw new Error('Failed to refresh token');
  }

  const data = (await response.json()) as RefreshTokenResponse;
  localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, data.token);
  localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, data.refresh_token);

  return data.token;
};
