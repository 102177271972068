import { t } from 'i18next';
import React, { useEffect } from 'react';
import {
  AutocompleteFilter,
  DatePickerFilter,
  SplitButtonFilter,
} from 'src/common/components/filters';
import ResetFiltersButton from 'src/common/components/ResetFiltersButton';
import { TagSummary } from 'src/common/components/TagSummary';
import { TotalItems } from 'src/common/components/TotalItems';
import { FilterLayout } from 'src/common/layouts/react-admin/FilterLayout';
import { useListExportModalContext } from 'src/common/modals/hooks/useListExportModalContext';
import TaggedFilter from 'src/contexts/common/filters/tagged-filter-context/components/TaggedFilter';
import MemberSearchFilterEnum from 'src/data/enum/local/searchFilterEnum';
import AdminResourceEnum from 'src/data/enum/remote/admin/adminResourceEnum';
import ClubResourceEnum from 'src/data/enum/remote/club/club-resource-enum';
import { Option } from 'src/domains/club/clubs/utils/clubOptions';
import { activeMemberOption, memberTypeOption } from 'src/domains/club/members/utils/memberOptions';
import { useDatePickerRangeHandler } from 'src/hooks/useDatePickerRangeHandler';
import { useReactAdminFilters } from 'src/hooks/useReactAdminFilters';
import { tokens } from 'src/locales/tokens';
import { FilterLabels, FilterValues } from 'src/types/enums';
import { getDateYearsAgo } from 'src/utils/getDateYearsAgo';

export const MemberFilters: React.FC = () => {
  const { filterValues, setFilters } = useReactAdminFilters();
  const { startDate, endDate, setStartDate, setEndDate } = useDatePickerRangeHandler();
  const { setGetFilters } = useListExportModalContext();

  useEffect(() => {
    setGetFilters(() => () => filterValues);
  }, [filterValues, setGetFilters]);

  return (
    <FilterLayout>
      <>
        <AutocompleteFilter
          resource={ClubResourceEnum.CLUBS}
          itemLabel="name"
          order="ASC"
          label={t(tokens.domains.members.filters.clubs)}
          filterKey={MemberSearchFilterEnum.CLUB_IDS}
          filterValue="id"
          placeholder={t(tokens.placeholder.clubSearch)}
        />
        <AutocompleteFilter
          resource={AdminResourceEnum.ORGANIZATION}
          itemLabel="name"
          order="ASC"
          label={t(tokens.domains.members.filters.organization)}
          filterKey={MemberSearchFilterEnum.ORGANIZATION}
          filterValue="id"
          placeholder={t(tokens.placeholder.organizationSearch)}
        />
        <AutocompleteFilter
          resource={AdminResourceEnum.AGENCY}
          itemLabel="name"
          order="ASC"
          label={t(tokens.domains.members.filters.agencies)}
          filterKey={MemberSearchFilterEnum.AGENCY}
          filterValue="id"
          placeholder={t(tokens.placeholder.agencySearch)}
        />
        <AutocompleteFilter
          resource={ClubResourceEnum.MEMBERS}
          itemLabel="fullName"
          order="ASC"
          label={t(tokens.domains.members.filters.memberName)}
          filterKey={MemberSearchFilterEnum.MEMBER}
          filterValue="id"
          placeholder={t(tokens.placeholder.memberSearch)}
        />
      </>
      <>
        <TotalItems />
      </>
      <>
        <TaggedFilter
          component={SplitButtonFilter}
          label={FilterLabels.Type}
          value={FilterValues.MEMBER_TYPE}
          options={memberTypeOption as Option[]}
          filterValues={filterValues}
          setFilters={setFilters}
        />
        <TaggedFilter
          component={SplitButtonFilter}
          label={FilterLabels.Active}
          value={FilterValues.IS_ACTIVE}
          options={activeMemberOption as Option[]}
          filterValues={filterValues}
          setFilters={setFilters}
        />
        <TaggedFilter
          component={DatePickerFilter}
          label={FilterLabels.CreatedAt}
          value={FilterValues.CREATED_AFTER}
          minDate={getDateYearsAgo(15)}
          maxDate={endDate}
          setStartDate={setStartDate}
          filterValues={filterValues}
          setFilters={setFilters}
        />
        <TaggedFilter
          component={DatePickerFilter}
          minDate={startDate}
          label={FilterLabels.EndAt}
          value={FilterValues.CREATED_BEFORE}
          setEndDate={setEndDate}
          filterValues={filterValues}
          setFilters={setFilters}
        />
        <ResetFiltersButton
          setFilters={setFilters}
          filterValues={filterValues}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
      </>
      <>
        <TagSummary
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          filterValues={filterValues}
          setFilters={setFilters}
        />
      </>
    </FilterLayout>
  );
};
