import { Chip, Stack, Typography, useTheme } from '@mui/material';
import React, { ReactElement } from 'react';

type chipContent = {
  text: string;
  icon: ReactElement;
  onClick: () => void;
};

interface SimpleFieldProps {
  label: string;
  chips: chipContent[];
}

export const ChipField: React.FC<SimpleFieldProps> = ({ label, chips }) => {
  const theme = useTheme();

  return (
    <Stack>
      <Typography
        variant="body2"
        fontWeight={'medium'}
      >
        {label}
      </Typography>
      <Stack
        direction="row"
        spacing={1}
        sx={{
          paddingTop: '15px',
        }}
      >
        {chips.map((chip, index) => (
          <Chip
            key={index}
            label={chip.text}
            variant="outlined"
            icon={chip.icon}
            color="primary"
            sx={{
              borderColor: theme.palette.primary.main,
            }}
            onClick={chip.onClick}
          />
        ))}
      </Stack>
    </Stack>
  );
};
