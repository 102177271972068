import { Grid } from '@mui/material';
import { t } from 'i18next';
import { CardStat } from 'src/domains/club/clubs/components/CardStat';
import { tokens } from 'src/locales/tokens';

interface StatsBundleProps {
  data: Registration[];
}

// TODO: Will be replaced by admin-api-types when it will be available
type Registration = {
  status?: string;
  firstName?: string;
  lastName?: string;
  jobTitle?: string;
  email?: string;
  phoneNumber?: string;
  organizationName?: string;
  clubName?: string;
  clubDepartment?: string;
  createdAt?: string;
  updatedAt?: string;
  id?: string;
};

export const StatsBundle = ({ data }: StatsBundleProps) => {
  return (
    <Grid
      container
      spacing={1}
      width={'100%'}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: { sm: 'column', md: 'row' },
      }}
    >
      <Grid
        item
        md={2}
        sx={{ paddingLeft: '0px', marginLeft: { sm: '0px', md: '-10px' } }}
      >
        <CardStat
          statIconSrc="/assets/icons/icon-sign.svg"
          statName={t(tokens.domains.clubs.detail.membershipsStats.pendingSignature)}
          statNumber={data?.filter((item) => item.status === 'pending_sign').length ?? 0}
        />
      </Grid>
      <Grid
        item
        md={2}
      >
        <CardStat
          statIconSrc="/assets/icons/icon-wait.svg"
          statName={t(tokens.domains.clubs.detail.membershipsStats.pendingValidation)}
          statNumber={data?.filter((item) => item.status === 'user_accepted').length ?? 0}
        />
      </Grid>
      <Grid
        item
        md={2}
      >
        <CardStat
          statIconSrc="/assets/icons/icon-no.svg"
          statName={t(tokens.domains.clubs.detail.membershipsStats.prospectDeclined)}
          statNumber={data?.filter((item) => item.status === 'user_refused').length ?? 0}
        />
      </Grid>
      <Grid
        item
        md={2}
      >
        <CardStat
          statIconSrc="/assets/icons/icon-rejected.svg"
          statName={t(tokens.domains.clubs.detail.membershipsStats.presidentDeclined)}
          statNumber={data?.filter((item) => item.status === 'president_refused').length ?? 0}
        />
      </Grid>
      <Grid
        item
        md={2}
      >
        <CardStat
          statIconSrc="/assets/icons/icon-expired.svg"
          statName={t(tokens.domains.clubs.detail.membershipsStats.expired)}
          statNumber={data?.filter((item) => item.status === 'expired').length ?? 0}
        />
      </Grid>
    </Grid>
  );
};
