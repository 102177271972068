import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Box, Chip, Stack, TextField as MUITextField } from '@mui/material';
import { t } from 'i18next';
import {
  FunctionField,
  Identifier,
  RaRecord,
  required,
  SaveButton,
  SimpleForm,
  useShowContext,
  TextField,
  DateField,
} from 'react-admin';
import { Club } from 'src/admin-api-types';
import { TopLabelField } from 'src/components/react-admin/core/fields';
import { ChipField } from 'src/domains/club/members/detail/fields/Chipfield';
import { CustomInputLabel } from 'src/domains/club/members/detail/fields/CustomInputLabel';
import { useAuth } from 'src/domains/system/auth/hooks/useAuth';
import { tokens } from 'src/locales/tokens';
import { UserRole } from 'src/types/enums/user-roles-enum';
import { createAndTriggerDownload } from 'src/utils/createAndTriggerDownload';

import { ClubSourceEnum } from '../../enums/club-source-enum';
import { ClubInfoContentSkeleton } from '../../skeletons';

export const ClubInfoContent: React.FC = () => {
  const { user } = useAuth();
  const { roles } = user || {};
  const isAgency = roles ? roles.includes(UserRole.AGENCY) : false;
  const { record, isFetching } = useShowContext<RaRecord<Identifier>>();

  if (isFetching) {
    return <ClubInfoContentSkeleton />;
  }

  const downloadContract = () => {
    if (!record || !record?.contract?.contractUrl) {
      console.error('No contract URL available');
      return;
    }
    createAndTriggerDownload(record.contract.contractUrl, 'true');
  };

  const viewContract = () => {
    if (!record || !record?.contract?.contractUrl) {
      console.error('No contract URL available');
      return;
    }

    window.open(record.contract.contractUrl as string, '_blank');
  };

  return (
    <SimpleForm
      toolbar={false}
      sx={{
        padding: '0px !important',
      }}
    >
      <Stack
        alignItems="stretch"
        width={'100%'}
      >
        <Stack flexDirection="row">
          <Box
            flexBasis={'50%'}
            padding="0px 24px 30px 0px"
          >
            <CustomInputLabel
              source={ClubSourceEnum.NAME}
              label={t(tokens.domains.members.detail.label.clubName)}
              validate={required()}
              startAdornment
              prefix="DYNABUY CLUB"
              disabled={isAgency}
            />
          </Box>

          <Box
            flexBasis={'50%'}
            padding="0px 24px 30px 0px"
          >
            <TopLabelField label={t(tokens.domains.members.detail.label.format)}>
              <TextField source={'format'} />
            </TopLabelField>
          </Box>
        </Stack>
        <Stack flexDirection="row">
          <Box
            flexBasis="50%"
            padding="0px 24px 30px 0px"
          >
            <TopLabelField
              label={t(tokens.domains.members.detail.label.signatoryAgency)}
              source={record?.parentAgency ? ClubSourceEnum.PARENT_AGENCY : ClubSourceEnum.AGENCY}
            >
              <FunctionField
                render={(record: Club) => {
                  return (
                    <Box
                      sx={{
                        width: '100%',
                      }}
                    >
                      <MUITextField
                        id="outlined-basic"
                        variant="outlined"
                        defaultValue={record?.agency?.name}
                        disabled={true}
                        hiddenLabel={true}
                        sx={{
                          width: '100%',
                        }}
                      />
                    </Box>
                  );
                }}
              />
            </TopLabelField>
          </Box>
          <FunctionField
            render={() => {
              return (
                <ChipField
                  {...{
                    label: t(tokens.domains.members.detail.label.clubContract),
                    chips: [
                      {
                        text: t(tokens.domains.clubs.chips.download),
                        icon: <FileDownloadOutlinedIcon color="primary" />,
                        onClick: () => downloadContract(),
                      },
                      {
                        text: t(tokens.domains.clubs.chips.see),
                        icon: <RemoveRedEyeIcon color="primary" />,
                        onClick: () => viewContract(),
                      },
                    ],
                  }}
                />
              );
            }}
          />
        </Stack>
        <Stack
          flexDirection={'row'}
          gap={4}
        >
          {record?.parentAgency ? (
            <Box
              flexBasis={'50%'}
              padding={'0px 24px 30px 0px'}
            >
              <TopLabelField label={t(tokens.domains.members.detail.label.attachmentAgency)}>
                <TextField source={'parentAgency.name'} />
              </TopLabelField>
            </Box>
          ) : (
            <></>
          )}

          <TopLabelField label={t(tokens.domains.members.detail.label.createdAt)}>
            <DateField
              source={'contract.createdAt'}
              emptyText={'-'}
            />
          </TopLabelField>

          <TopLabelField label={t(tokens.domains.members.detail.label.signedAt)}>
            <DateField
              source={'contract.signedAt'}
              emptyText={'-'}
            />
          </TopLabelField>

          <TopLabelField label={t(tokens.domains.members.detail.label.disabledAt)}>
            <DateField
              source={'contract.disabledAt'}
              emptyText={'-'}
            />
          </TopLabelField>
        </Stack>
      </Stack>

      <Box
        display={'flex'}
        justifyContent={'end'}
        width={'100%'}
        margin="24px 0px"
      >
        <SaveButton
          label={t(tokens.common.button.validate)}
          type="submit"
          icon={<></>}
        />
      </Box>
    </SimpleForm>
  );
};
