import polyglotI18nProvider from 'ra-i18n-polyglot';
import fr from 'ra-language-french';
import React from 'react';
import { CoreAdminContext, CoreAdminUI, Resource } from 'react-admin';
import ClubUserRecordRepresentation from 'src/components/react-admin/representations/club/ClubUserRecordRepresentation';
import queryClient from 'src/config/queryClient';
import AdminResourceEnum from 'src/data/enum/remote/admin/adminResourceEnum';
import ClubResourceEnum from 'src/data/enum/remote/club/club-resource-enum';
import dataProvider from 'src/data/provider/remote/dataProvider';
import UserList from 'src/domains/admin/users/CubList';
import { UserShowWithAccess } from 'src/domains/admin/users/detail';
import { ClubList, ClubShow } from 'src/domains/club/clubs';
import { ClubCreate } from 'src/domains/club/clubs/ClubCreate';
import { NewContract } from 'src/domains/club/contract/NewContract';
import HelpdeskTicketList from 'src/domains/club/helpdesk/HelpdeskTicketList';
import HelpdeskTicketShow from 'src/domains/club/helpdesk/HelpdeskTicketShow';
import { MemberList, MemberShow } from 'src/domains/club/members';
import { RegistrationsList } from 'src/domains/club/registrations/RegistrationsList';
import NotFoundPage from 'src/domains/system/error/NotFoundPage';
import { Layout } from 'src/layouts/dashboard';

const i18nProvider = polyglotI18nProvider(() => fr, 'fr');

const Admin: React.FC = () => {
  return (
    <CoreAdminContext
      basename={'/'}
      i18nProvider={i18nProvider}
      queryClient={queryClient}
      dataProvider={dataProvider}
    >
      <CoreAdminUI
        layout={Layout}
        catchAll={NotFoundPage}
      >
        <Resource
          name={ClubResourceEnum.CLUBS}
          list={ClubList}
          show={ClubShow}
          create={ClubCreate}
          recordRepresentation={'name'}
        />

        <Resource
          name={ClubResourceEnum.MEMBERS}
          list={MemberList}
          show={MemberShow}
        />

        <Resource
          name={ClubResourceEnum.REGISTRATIONS}
          list={RegistrationsList}
        />

        <Resource name={AdminResourceEnum.AGENCY} />
        <Resource name={AdminResourceEnum.ORGANIZATION} />

        <Resource
          name={AdminResourceEnum.ADMIN_USER}
          list={UserList}
          show={UserShowWithAccess}
          recordRepresentation={(record) => record.fullName}
        />

        <Resource
          name={ClubResourceEnum.HELPDESK_TICKET}
          show={HelpdeskTicketShow}
          list={HelpdeskTicketList}
        />

        <Resource
          name={ClubResourceEnum.HELPDESK_TOPIC}
          recordRepresentation={(record) => record.label}
        />

        <Resource
          name={ClubResourceEnum.CLUB_USER}
          recordRepresentation={<ClubUserRecordRepresentation />}
        />

        <Resource
          name={ClubResourceEnum.CONTRACT}
          create={NewContract}
        />
      </CoreAdminUI>
    </CoreAdminContext>
  );
};

export default Admin;
