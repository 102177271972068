/**
 * This function is intended to be used in a catch block to handle API errors.
 * It checks if the error is an instance of Error and, if so, throws a new error with the same message.
 * Otherwise, it throws a new error with a generic message.
 *
 * @param err - The error to handle.
 */
export const handleCatchBlockError = (err: unknown) => {
  if (err instanceof Error) {
    throw new Error(err.message);
  } else {
    throw new Error('An error occurred');
  }
};
