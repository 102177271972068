import { t } from 'i18next';
import { tokens } from 'src/locales/tokens';
import { warning, success, info, yellow, error, indigo, green } from 'src/theme/colors';
import { FormatOptionValuesEnum, StatusOptionValuesEnum } from 'src/types/enums';

export type Option = {
  label: string;
  value: string;
};

export const formatOptionsTrad = {
  monthly: t(tokens.filters.format.monthly),
  fortnightly: t(tokens.filters.format.fortnightly),
  weekly: t(tokens.filters.format.weekly),
} as const;

const FormatOptionValues = {
  monthly: FormatOptionValuesEnum.monthly,
  fortnightly: FormatOptionValuesEnum.fortnightly,
  weekly: FormatOptionValuesEnum.weekly,
} as const;

export const formatOptions = [
  {
    label: formatOptionsTrad.monthly,
    value: FormatOptionValues.monthly,
  },
  {
    label: formatOptionsTrad.fortnightly,
    value: FormatOptionValues.fortnightly,
  },
  {
    label: formatOptionsTrad.weekly,
    value: FormatOptionValues.weekly,
  },
];

const statusOptionsTrad = {
  initial: t(tokens.filters.status.initial),
  active: t(tokens.filters.status.active),
  inactive: t(tokens.filters.status.inactive),
  declined: t(tokens.filters.status.declined),
  voided: t(tokens.filters.status.voided),
} as const;

const statusOptionsValues = {
  initial: StatusOptionValuesEnum.INITIAL,
  active: StatusOptionValuesEnum.ACTIVE,
  inactive: StatusOptionValuesEnum.INACTIVE,
  declined: StatusOptionValuesEnum.DECLINED,
  voided: StatusOptionValuesEnum.VOIDED,
} as const;

export const clubStatusOptions = [
  {
    label: statusOptionsTrad.initial,
    value: statusOptionsValues.initial,
    tagColor: {
      background: info.alpha30,
      text: info.dark,
    },
  },
  {
    label: statusOptionsTrad.active,
    value: statusOptionsValues.active,
    tagColor: {
      background: success.alpha30,
      text: success.dark,
    },
  },
  {
    label: statusOptionsTrad.inactive,
    value: statusOptionsValues.inactive,
    tagColor: {
      background: warning.alpha50,
      text: warning.dark,
    },
  },
  {
    label: statusOptionsTrad.declined,
    value: statusOptionsValues.declined,
    tagColor: {
      background: warning.alpha50,
      text: warning.dark,
    },
  },
  {
    label: statusOptionsTrad.voided,
    value: statusOptionsValues.voided,
    tagColor: {
      background: warning.dark,
      text: warning.light,
    },
  },
];

export const typeOptionsTrad = {
  president: t(tokens.filters.type.president),
  member: t(tokens.filters.type.member),
} as const;

export const TypeOptionValues = {
  president: 'true',
  member: 'false',
} as const;

export const typeOptions = [
  {
    label: typeOptionsTrad.president,
    value: TypeOptionValues.president,
  },
  {
    label: typeOptionsTrad.member,
    value: TypeOptionValues.member,
  },
];
