import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import { useListContext } from 'react-admin';
import { tokens } from 'src/locales/tokens';

export const TotalItems = () => {
  const { total } = useListContext();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'right',
      }}
    >
      <Typography variant="body1">{`${total || 0} ${t(tokens.domains.clubs.results)}`}</Typography>
    </Box>
  );
};
