import { Box, CircularProgress } from '@mui/material';
import { t } from 'i18next';
import debounce from 'lodash/debounce';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { AutocompleteInput } from 'react-admin';
import { useForm } from 'react-hook-form';
import AddressSearchInput from 'src/common/components/react-admin/inputs/generic/search/address/AddressSearchInput';
import AddressSearchSkeleton from 'src/common/components/react-admin/inputs/generic/search/address/AddressSearchSkeleton';
import { FirstStepType } from 'src/domains/club/contract/context/contractContextType';
import useFetchAddressData from 'src/domains/club/contract/hooks/useFetchAddressData';
import { AddressFeature } from 'src/domains/club/contract/types/postalAddress.types';
import { tokens } from 'src/locales/tokens';

interface PostalAddressProps {
  source: keyof FirstStepType;
  name: string;
  disabled?: boolean;
  placeholder: string;
  firstStep?: FirstStepType;
  setFirstStep?: Dispatch<SetStateAction<FirstStepType>>;
  updateTempAddress: Dispatch<SetStateAction<Partial<FirstStepType>>>;
}

export const PostalAddress: React.FC<PostalAddressProps> = ({
  source,
  placeholder,
  firstStep,
  setFirstStep,
  updateTempAddress,
}) => {
  const { choices, fetchAddressData, setChoices } = useFetchAddressData();
  useEffect(() => {
    if (firstStep && firstStep[source]) {
      setChoices([{ properties: { label: firstStep[source] } } as AddressFeature]);
      void fetchAddressData(firstStep[source] as string);
    }
  }, [fetchAddressData, firstStep, setChoices, source]);

  useEffect(() => {
    if (choices.length === 1 && choices[0].geometry) {
      const address = {
        apiStreet: choices[0].properties.name,
        apiPostalCode: choices[0].properties.postcode,
        apiCity: choices[0].properties.city,
        apiLongitude: choices[0].geometry.coordinates[0],
        apiLatitude: choices[0].geometry.coordinates[1],
      };
      updateTempAddress(address);
    }
  }, [choices, firstStep, setFirstStep, updateTempAddress, source]);

  //! todo when backend is ready
  // const record = useRecordContext();
  // if (record) {
  //   record.city = selectedAddress.properties.city;
  //   record.name = selectedAddress.properties.label;
  //   record.postcode = selectedAddress.properties.postcode;
  // }

  const handleOptionChange = useCallback(
    (option: AddressFeature) => {
      const address =
        option !== null
          ? {
              apiStreet: option.properties.name,
              apiPostalCode: option.properties.postcode,
              apiCity: option.properties.city,
              apiLatitude: option.geometry.coordinates[1],
              apiLongitude: option.geometry.coordinates[0],
              fullAddress: option.properties.label,
            }
          : null;
      updateTempAddress(address);
    },
    [updateTempAddress, source, setFirstStep, firstStep]
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      position="relative"
    >
      <AddressSearchInput
        placeholder={placeholder}
        onOptionChange={handleOptionChange}
        source={source}
      />
    </Box>
  );
};
