import { Grid, Skeleton } from '@mui/material';

export const StatsBundleSkeleton = () => {
  return (
    <>
      <Grid
        container
        spacing={1}
        width={'100%'}
        sx={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Grid
          item
          md={2}
          sx={{ paddingLeft: '0px', marginLeft: '-10px' }}
        >
          <Skeleton
            variant="rounded"
            height={100}
          />
        </Grid>
        <Grid
          item
          md={2}
        >
          <Skeleton
            variant="rounded"
            height={100}
          />
        </Grid>
        <Grid
          item
          md={2}
        >
          <Skeleton
            variant="rounded"
            height={100}
          />
        </Grid>
        <Grid
          item
          md={2}
        >
          <Skeleton
            variant="rounded"
            height={100}
          />
        </Grid>
        <Grid
          item
          md={2}
        >
          <Skeleton
            variant="rounded"
            height={100}
          />
        </Grid>
      </Grid>
    </>
  );
};
