import { Typography } from '@mui/material';
import Stack from '@mui/system/Stack';
import { t } from 'i18next';
import { DateField, FunctionField, ShowButton, TextField } from 'react-admin';
import { ContractClubMember, Member } from 'src/admin-api-types';
import { ColumnHeaderLabel } from 'src/common/enums/react-admin';
import { DatagridLayout } from 'src/common/layouts/react-admin';
import { YesNoChipField } from 'src/components/react-admin/core/fields';
import { MemberSortByEnum } from 'src/data/enum/local/orderFilterEnum';
import { ClubSourceEnum } from 'src/domains/club/clubs/enums/club-source-enum';
import { useAuth } from 'src/domains/system/auth/hooks/useAuth';
import { tokens } from 'src/locales/tokens';
import { UserRole } from 'src/types/enums/user-roles-enum';
import { translatePaymentPeriod } from 'src/utils/translatePaymentPeriod';

import { MemberColumnSourceEnum } from '../enums/column-source-enum';

export const MemberGrid = () => {
  const { user } = useAuth();
  const { roles } = user || {};
  const isAdmin = roles
    ? roles.includes(UserRole.ADMIN) || roles.includes(UserRole.SUPER_ADMIN)
    : false;

  return (
    <DatagridLayout content={t(tokens.domains.members.list.grid.noResult)}>
      <FunctionField
        sortBy={MemberSortByEnum.MEMBER_NAME}
        label={ColumnHeaderLabel.memberName}
        render={() => {
          return (
            <Stack>
              <TextField source={'fullName'} />
              <TextField
                source={'email'}
                variant={'caption'}
              />
              <TextField
                source={'phone'}
                variant={'caption'}
              />
            </Stack>
          );
        }}
      ></FunctionField>

      <TextField
        sortBy={MemberSortByEnum.ORGANIZATION_NAME}
        label={ColumnHeaderLabel.organization}
        source={'organizationName'}
      />
      <TextField
        sortBy={MemberSortByEnum.CLUB_NAME}
        label={ColumnHeaderLabel.clubName}
        source={'clubName'}
      />

      <FunctionField
        label={ColumnHeaderLabel.agency}
        render={(record: Member) => {
          const parentAgencyName: string | undefined = record?.parentAgency?.name;
          const agencyName: string | undefined = record?.agency?.name;
          if (isAdmin && parentAgencyName) {
            return (
              <Stack>
                <Typography>{parentAgencyName}</Typography>
                <Typography sx={{ fontSize: '12px', color: 'text.secondary' }}>
                  {agencyName}
                </Typography>
              </Stack>
            );
          }
          return agencyName || '-';
        }}
      />
      <YesNoChipField
        label="Rôle"
        yesText={'Président'}
        noText={'Membre'}
        source={'isPresident'}
      />

      <TextField
        sortBy={MemberSortByEnum.ORGANISATION_DEPARTMENT}
        label={ColumnHeaderLabel.department}
        source={ClubSourceEnum.DEPARTMENT}
      />
      <FunctionField
        label={ColumnHeaderLabel.payment}
        render={(record: ContractClubMember) => translatePaymentPeriod(record)}
      />
      <YesNoChipField
        source={'isActive'}
        label={ColumnHeaderLabel.isActive}
      />
      <DateField
        sortBy={MemberSortByEnum.CREATED_AT}
        source={MemberColumnSourceEnum.CREATED_AT}
        label={ColumnHeaderLabel.createdAt}
      />
      <FunctionField
        label={ColumnHeaderLabel.action}
        render={() => <ShowButton />}
      />
    </DatagridLayout>
  );
};
