import * as Sentry from '@sentry/react';
import { jwtDecode } from 'jwt-decode';

function setUserInSentry() {
  const token = localStorage.getItem('accessToken');

  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      const email = (decodedToken as { email?: string })?.email;
      if (email) {
        Sentry.setUser({ email });
      }
    } catch (error) {
      console.error('Failed to decode JWT', error);
      Sentry.captureException(error); // Capture l'erreur pour Sentry
    }
  }
}

export default setUserInSentry;
