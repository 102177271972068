import { Stack } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { t } from 'i18next';
import React, { Dispatch, SetStateAction } from 'react';
import { PostalAddress } from 'src/domains/club/contract/components/PostalAddress';
import { contractContext, FirstStepType } from 'src/domains/club/contract/context';
import { SectionLayout } from 'src/domains/club/contract/layouts/SectionLayout';
import { countryOptions } from 'src/domains/club/contract/steps/stepTwo/utils/';
import { CustomInputLabel } from 'src/domains/club/members/detail/fields/CustomInputLabel';
import { CustomSelectLabel } from 'src/domains/club/members/detail/fields/CustomSelectLabel';
import { TopLabelInputLayout } from 'src/domains/club/members/detail/fields/TopLabelInputLayout';
import { tokens } from 'src/locales/tokens';

interface MeetingPlaceProps {
  isCheckboxChecked: boolean;
  setIsCheckboxChecked: (value: boolean) => void;
  setTempAddress: Dispatch<SetStateAction<Partial<FirstStepType>>>;
  setTempClosestAddress: Dispatch<SetStateAction<Partial<FirstStepType>>>;
}

export const MeetingPlace: React.FC<MeetingPlaceProps> = ({
  isCheckboxChecked,
  setIsCheckboxChecked,
  setTempAddress,
  setTempClosestAddress,
}) => {
  const { firstStep, setFirstStep } = React.useContext(contractContext);

  return (
    <SectionLayout title={t(tokens.domains.contract.stepOne.meetingPlace.title)}>
      <Stack flexDirection={'row'}>
        <Stack
          flexGrow="1"
          padding={'0px 24px 30px 24px'}
          spacing={4}
          flexBasis={'50%'}
        >
          <Stack
            direction={'row'}
            spacing={3}
            gap="55px"
          >
            <TopLabelInputLayout
              label={`${t(tokens.domains.contract.stepOne.meetingPlace.address.label)} *`}
            >
              <PostalAddress
                placeholder={t(
                  tokens.domains.contract.stepOne.meetingPlace.fullAddress.placeholder
                )}
                source="fullAddress"
                name="fullAddress"
                firstStep={firstStep}
                setFirstStep={setFirstStep}
                updateTempAddress={setTempAddress}
              />
            </TopLabelInputLayout>
            <CustomInputLabel
              source="place"
              name="place"
              label={`${t(tokens.domains.contract.stepOne.meetingPlace.meetingName.label)} *`}
              placeholder={t(tokens.domains.contract.stepOne.meetingPlace.meetingName.placeholder)}
            />
          </Stack>
          <FormGroup
            sx={{
              marginTop: '0px !important',
              '& .MuiFormControlLabel-label': {
                fontSize: '14px',
              },
            }}
          >
            <FormControlLabel
              control={<Checkbox />}
              label={t(tokens.domains.contract.stepOne.meetingPlace.addressNotFound)}
              checked={isCheckboxChecked}
              onChange={() => {
                setIsCheckboxChecked(!isCheckboxChecked);
              }}
            />
          </FormGroup>
        </Stack>
      </Stack>
      {isCheckboxChecked && (
        <Stack flexDirection={'row'}>
          <Stack
            flexGrow="1"
            padding={'0px 24px 30px 24px'}
            spacing={4}
            flexBasis={'50%'}
          >
            <Stack
              direction={'row'}
              spacing={3}
              gap="55px"
            >
              <CustomInputLabel
                source="address"
                name="address"
                label={`${t(tokens.domains.contract.stepOne.meetingPlace.address.label)} *`}
                placeholder={t(tokens.domains.contract.stepOne.meetingPlace.address.placeholder)}
              />
              <CustomInputLabel
                source="postalCode"
                name="postalCode"
                label={`${t(tokens.domains.contract.stepOne.meetingPlace.postalCode.label)} *`}
                placeholder={t(tokens.domains.contract.stepOne.meetingPlace.postalCode.placeholder)}
              />
            </Stack>
            <Stack
              direction={'row'}
              spacing={3}
              gap="55px"
            >
              <CustomInputLabel
                source="city"
                name="city"
                label={`${t(tokens.domains.contract.stepOne.meetingPlace.city.label)} *`}
                placeholder={t(tokens.domains.contract.stepOne.meetingPlace.city.placeholder)}
              />
              <CustomSelectLabel
                source="country"
                name="country"
                label={`${t(tokens.domains.contract.stepOne.meetingPlace.country.label)} *`}
                choices={countryOptions}
                firstStep={firstStep}
                setFirstStep={setFirstStep}
                disabled
              />
            </Stack>
            <Stack
              direction={'row'}
              spacing={3}
              gap="55px"
              width={'calc(50% - 30px)'}
            >
              <TopLabelInputLayout
                label={`${t(tokens.domains.contract.stepOne.meetingPlace.closestAddress.label)} *`}
              >
                <PostalAddress
                  placeholder={t(
                    tokens.domains.contract.stepOne.meetingPlace.closestAddress.placeholder
                  )}
                  source="closestAddress"
                  name="closestAddress"
                  firstStep={firstStep}
                  updateTempAddress={setTempClosestAddress}
                />
              </TopLabelInputLayout>
            </Stack>
          </Stack>
        </Stack>
      )}
    </SectionLayout>
  );
};
