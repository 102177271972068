import { Box } from '@mui/system';
import React from 'react';
import { Pagination } from 'react-admin';
import { ListBaseLayout } from 'src/common/layouts/react-admin/ListBaseLayout';

import { ClubMembershipFilters } from '../../clubs/components/filters/ClubMembershipFilters';
import { ClubMembershipGrid } from '../../clubs/components/grid/ClubMembershipGrid';

import { RegistrationsStats } from './RegistrationsStats';

export const RegistrationListTable: React.FC = () => {
  return (
    <ListBaseLayout>
      <Box sx={{ marginBottom: 4, marginLeft: 1 }}>
        <RegistrationsStats />
      </Box>
      <ClubMembershipFilters />
      <ClubMembershipGrid />
      <Pagination />
    </ListBaseLayout>
  );
};
