import { t } from 'i18next';
import React from 'react';
import { ShowBase } from 'react-admin';
import { BreadCrumb } from 'src/common/components/breadcrumb';
import { DetailLayout } from 'src/common/layouts/react-admin/DetailLayout';
import { tokens } from 'src/locales/tokens';
import { paths } from 'src/paths';

import { ClubShowGeneral } from './ClubShowGeneral';
import { ClubShowMemberships } from './ClubShowMemberships';
import { ClubDetailHeader } from './components/ClubDetailHeader';
import { ClubTabDetails } from './components/filters/ClubTabDetails';

export const ClubShow = () => {
  const path = paths.club.clubs.list;

  const [tabSelected, setTabSelected] = React.useState(0);

  const handleChangeTab = (_event: React.SyntheticEvent, newValue: number) => {
    setTabSelected(newValue);
  };
  return (
    <ShowBase>
      <DetailLayout seoTitle={t(tokens.seo.club.title)}>
        <BreadCrumb
          linkText={t(tokens.breadcrumb.clubList)}
          to={path}
        />
        <ClubDetailHeader />
        <ClubTabDetails
          value={tabSelected}
          onChange={handleChangeTab}
        />
        {tabSelected === 0 && <ClubShowGeneral />}
        {tabSelected === 1 && <ClubShowMemberships />}
      </DetailLayout>
    </ShowBase>
  );
};
