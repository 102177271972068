import { Box } from '@mui/system';
import React from 'react';

import { ContractSent } from './components/ContractSent';

export const ContratStepThree: React.FC = () => {
  return (
    <>
      <Box
        alt="Validé"
        component="img"
        src="/assets/icons/check-mark.svg"
        sx={{
          height: 'auto',
          width: '60px',
          paddingTop: '32px',
          marginLeft: '50%',
          transform: 'translateX(-50%)',
        }}
      />
      <ContractSent />
    </>
  );
};
