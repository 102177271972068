import { parseHydraDocumentation } from '@api-platform/api-doc-parser';
import { Api } from '@api-platform/api-doc-parser/src';
import {
  ACCESS_TOKEN_STORAGE_KEY,
  REFRESH_TOKEN_STORAGE_KEY,
} from 'src/contexts/auth/jwt/AuthProvider';

import { ENTRYPOINT } from '../provider/remote/dataProvider';

import { getAuthenticationHeader } from './getAuthenticationHeader';

export const apiDocumentationParser = async () => {
  try {
    return await parseHydraDocumentation(ENTRYPOINT, { headers: getAuthenticationHeader });
  } catch (result: unknown) {
    if (typeof result === 'object' && result !== null) {
      const { api, response, status } = result as {
        api: Api;
        response: Response;
        status: number;
      };
      if (status !== 401 || !response) {
        throw result;
      }

      // Prevent infinite loop if the token is expired
      localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
      localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);

      return {
        api,
        response,
        status,
      };
    }
    throw result;
  }
};
