import { useGetList } from 'react-admin';
import { StatsBundle } from 'src/common/components/StatsBundle';
import ClubResourceEnum from 'src/data/enum/remote/club/club-resource-enum';

import { StatsBundleSkeleton } from '../../clubs/skeletons/StatsBundleSkeleton';

export const RegistrationsStats = () => {
  const url = ClubResourceEnum.REGISTRATIONS;

  const { data, isLoading, error } = useGetList(url, {
    pagination: { page: 1, perPage: 1000000 },
  });

  if (isLoading) {
    return <StatsBundleSkeleton />;
  }
  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return <StatsBundle data={data ?? []} />;
};
