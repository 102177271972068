import { FirstStepType, SecondStepType, ValidationError } from './contractContextType';

export const initialFirstStep: FirstStepType = {
  name: '',
  ownerRate: 'value0',
  businessIdentifier: '',
  associationIdentifier: '',
  format: 'Convivial',
  dayWeek: 'value0',
  startEvent: '',
  openingTime: '',
  closingTime: '',
  fullAddress: '',
  place: '',
  address: '',
  postalCode: '',
  city: '',
  country: 'value0',
};

export const initialSecondStep: SecondStepType = {
  legalForm: 'value0',
  name: '',
  brand: '',
  companyRegister: '',
  activity: '',
  street: '',
  zipCode: '',
  city: '',
  country: 'value0',
  size: '',
  capital: '',
  genre: 'value0',
  meetingDay: 'value0',
  firstName: '',
  lastName: '',
  email: '',
  number: '',
  emailDisplayed: ['admin'],
  phoneDisplayed: ['admin'],
};

export const initialFormErrors: ValidationError = {};

export const initialIsCheckboxChecked = false;
