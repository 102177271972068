import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { t } from 'i18next';
import * as React from 'react';
import { tokens } from 'src/locales/tokens';

interface ClubTabDetailsProps {
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

export const ClubTabDetails = ({ value, onChange }: ClubTabDetailsProps) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={value}
        onChange={onChange}
        aria-label="club details tabs"
        selectionFollowsFocus
      >
        <Tab
          label={t(tokens.domains.clubs.detail.general)}
          sx={{ padding: '5px 0px 5px' }}
        />
        <Tab
          label={t(tokens.domains.clubs.detail.memberships)}
          sx={{ padding: '5px 0px 5px' }}
        />
      </Tabs>
    </Box>
  );
};
