import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Alert } from '@mui/material';
import Grid from '@mui/material/Grid';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { SimpleForm, useNotify } from 'react-admin';
import dataProvider from 'src/data/provider/remote/dataProvider';
import { ConfirmationModal } from 'src/domains/club/contract/components/ConfirmationModal';
import { ContractButton } from 'src/domains/club/contract/components/ContractButton';
import { contractContext } from 'src/domains/club/contract/context/contractContext';
import {
  SecondStepType,
  ValidationError,
} from 'src/domains/club/contract/context/contractContextType';
import { mapFormDataToFinalData } from 'src/domains/club/contract/utils';
import { tokens } from 'src/locales/tokens';

import { CompanyInfos, Signatory } from './components';
import {
  validateActivity,
  validateAddress,
  validateCompanyDetails,
  validateLegalForm,
  validateRcsCity,
  validateSignatory,
  validateSocialReason,
} from './validators';

interface ContratStepOneProps {
  step: number;
  setStep: (step: number) => void;
}

const validateStepTwo = (values: SecondStepType): ValidationError => {
  const errors: ValidationError = {};

  validateLegalForm(values, errors);
  validateSocialReason(values, errors);
  validateRcsCity(values, errors);
  validateActivity(values, errors);
  validateAddress(values, errors);
  validateCompanyDetails(values, errors);
  validateSignatory(values, errors);

  return errors;
};

export const ContratStepTwo: React.FC<ContratStepOneProps> = ({ step, setStep }) => {
  const [val, setVal] = useState<SecondStepType>();
  const { firstStep, secondStep, setSecondStep } = React.useContext(contractContext);
  const [isLoading, setIsLoading] = useState(false);
  const notify = useNotify();
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  const handleClose = (event: object, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason !== 'backdropClick') {
      setIsModalOpen(false);
    }
  };

  const onSubmit = (val: SecondStepType) => {
    setVal(val);
    setIsModalOpen(true);
  };

  const handleConfirmation = async () => {
    try {
      if (val) {
        setIsLoading(true);
        const data = mapFormDataToFinalData(firstStep, val);
        await dataProvider.postContract(data);
        setSecondStep({ ...secondStep, ...val });
        setStep(step + 1);
      }
    } catch (error) {
      notify(
        <Alert
          sx={{
            width: '100%',
          }}
          severity="warning"
        >
          {error}
        </Alert>,
        {
          type: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        }
      );
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <SimpleForm
        toolbar={false}
        defaultValues={secondStep}
        id={'contract_step_two'}
        onSubmit={onSubmit}
        validate={validateStepTwo}
      >
        <CompanyInfos />
        <Signatory />
        <Grid
          container
          gap={2}
          marginTop={4}
          justifyContent="flex-end"
        >
          <ContractButton
            color="primary"
            label={t(tokens.utils.step.previous)}
            handleClick={() => setStep(1)}
            variant="text"
            underline={true}
            type="button"
          />
          <ContractButton
            color="primary"
            label={t(tokens.utils.step.next)}
            variant="contained"
            endIcon={<ArrowForwardIosIcon />}
            iconStyle={{ fontSize: '12px !important' }}
          />
        </Grid>
        <ConfirmationModal
          open={isModalOpen}
          onClose={handleClose}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          handleConfirmation={handleConfirmation}
          isLoading={isLoading}
        />
      </SimpleForm>
    </>
  );
};
