import { Box, Card, Skeleton, Stack } from '@mui/material';
import { t } from 'i18next';
import { TopLabelField } from 'src/components/react-admin/core/fields';
import { tokens } from 'src/locales/tokens';

import { CardTitle } from '../common/CardTitle';

export const ClubDetailContentSkeleton = () => {
  return (
    <Card
      sx={{
        flexGrow: 1,
      }}
    >
      <CardTitle text={t(tokens.domains.members.detail.ClubCardTitle)} />
      <Stack
        flexGrow="1"
        padding={'0px 24px 30px 24px'}
        spacing={4}
        marginRight="100px"
      >
        <Stack
          direction={'row'}
          spacing={3}
        >
          <Box flexBasis="25%">
            <TopLabelField label={t(tokens.domains.members.detail.label.clubName)}>
              <Skeleton />
            </TopLabelField>
          </Box>
          <Box flexBasis="25%">
            <TopLabelField label={t(tokens.domains.helpdesk.detail.info.status)}>
              <Skeleton />
            </TopLabelField>
          </Box>
          <Box flexBasis="25%">
            <TopLabelField label={t(tokens.domains.members.detail.label.debitAmount)}>
              <Skeleton />
            </TopLabelField>
          </Box>
          <Box flexBasis="25%">
            <TopLabelField label={t(tokens.domains.members.detail.label.accountCreationDate)}>
              <Skeleton />
            </TopLabelField>
          </Box>
        </Stack>
        <Stack
          direction={'row'}
          spacing={3}
        >
          <Box flexBasis="25%">
            <TopLabelField label={t(tokens.domains.members.detail.label.signatoryAgency)}>
              <Skeleton />
            </TopLabelField>
          </Box>
          <Box flexBasis="25%">
            <TopLabelField label={t(tokens.domains.members.detail.label.type)}>
              <Skeleton />
            </TopLabelField>
          </Box>
          <Box flexBasis="25%">
            <TopLabelField label={t(tokens.domains.members.detail.label.paymentFrequency)}>
              <Skeleton />
            </TopLabelField>
          </Box>
          <Box flexBasis="25%">
            <TopLabelField label={t(tokens.domains.members.detail.label.contractSignatureDate)}>
              <Skeleton />
            </TopLabelField>
          </Box>
        </Stack>
        <Stack
          direction={'row'}
          spacing={3}
        >
          <Box flexBasis="25%">
            <TopLabelField label={t(tokens.domains.members.detail.label.attachmentAgency)}>
              <Skeleton />
            </TopLabelField>
          </Box>
          <Box flexBasis="25%">
            <TopLabelField
              label={t(tokens.domains.members.detail.label.membershipContractSignatory)}
            >
              <Skeleton />
            </TopLabelField>
          </Box>
          <Box flexBasis="25%">
            <TopLabelField label={t(tokens.domains.members.detail.label.methodOfPayment)}>
              <Skeleton />
            </TopLabelField>
          </Box>
          <Box flexBasis="25%">
            <TopLabelField label={t(tokens.domains.members.detail.label.contract)}>
              <Skeleton />
            </TopLabelField>
          </Box>
        </Stack>
      </Stack>
    </Card>
  );
};
