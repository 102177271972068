interface animateValueProps {
  obj: HTMLElement | null;
  start: number;
  end: number;
  duration: number;
}

/**
 * The `animateValue` function animates a value change on an HTML element over a specified duration.
 * @param {animateValueProps}  - - `obj`: The HTML element that will be updated with the animated
 * value.
 */
export function animateValue({ obj, start, end, duration }: animateValueProps) {
  let startTimestamp: number | null = null;
  const step = (timestamp: number) => {
    if (!startTimestamp) startTimestamp = timestamp;
    const progress = Math.min((timestamp - startTimestamp) / duration, 1);
    if (obj) {
      obj.innerHTML = Math.floor(progress * (end - start) + start).toString();
    }
    if (progress < 1) {
      window.requestAnimationFrame(step);
    }
  };
  window.requestAnimationFrame(step);
}
