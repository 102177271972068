import { FinalData, FirstStepType, SecondStepType } from 'src/domains/club/contract/context';
import { extractIdFromUrl } from 'src/utils/extractIdFromUrl';
import { phoneNumberWithoutZero } from 'src/utils/phoneNumberWithoutZero';

import { mapCountryCode, mapFormat, mapGenreToCivility, mapOwnerRate } from '.';

const CONTRACT_TYPE = 'club_president';
const COUNTRY = 'FR';
const COUNTRY_CODE = '+33';

export const mapFormDataToFinalData = (form1: FirstStepType, form2: SecondStepType): FinalData => {
  const street = form1.address || form1.apiStreet || null;
  const zipCode = form1.postalCode || form1.apiPostalCode || null;
  const city = form1.city || form1.apiCity || null;

  return {
    club: {
      name: `${form1.name}` || null,
      postalAddress: {
        place: form1.place || null,
        street,
        zipCode,
        city,
        country: COUNTRY,
        longitude: form1.apiLongitude || null,
        latitude: form1.apiLatitude || null,
      },
      closestPostalAddress: {
        street: form1.apiClosestStreet || null,
        zipCode: form1.apiClosestPostalCode || null,
        city: form1.apiClosestPostalCode || null,
        country: COUNTRY,
        longitude: form1.apiClosestLongitude || null,
        latitude: form1.apiClosestLatitude || null,
      },
      setting: {
        format: mapFormat(form1.format || null),
        dayWeek: Number(form1.dayWeek) || 1,
        startEvent: new Date(form1.startEvent || '').toISOString() || null,
        openingTime: new Date(`2024-01-01T${form1.openingTime}:00+00:00`).toISOString() || null,
        closingTime: new Date(`2024-01-01T${form1.closingTime}:00+00:00`).toISOString() || null,
      },
    },
    organization: {
      legalFormId: form2.legalForm ? extractIdFromUrl(form2.legalForm) : null,
      name: form2.name || null,
      brand: form2.brand || null,
      businessIdentifier: form1.businessIdentifier || null,
      associationIdentifier: form1.associationIdentifier || null,
      activity: form2.activity || null,
      size: parseInt(form2.size || '0'),
      capital: form2.capital || null,
      companyRegister: form2.companyRegister || null,
      postalAddress: {
        place: form1.place || null,
        street: form2.street || null,
        zipCode: form2.zipCode || null,
        city: form2.city || null,
        country: mapCountryCode(form2.country || null),
      },
    },
    signatory: {
      civility: mapGenreToCivility(form2.genre || null),
      firstName: form2.firstName || null,
      lastName: form2.lastName || null,
      email: form2.email || null,
      phone: {
        countryCode: COUNTRY_CODE,
        number: phoneNumberWithoutZero(form2.number) || null,
      },
      emailDisplayed: Array.isArray(form2.emailDisplayed) && form2.emailDisplayed.includes('admin'),
      phoneDisplayed: Array.isArray(form2.phoneDisplayed) && form2.phoneDisplayed.includes('admin'),
    },
    ownerRate: mapOwnerRate(form1.ownerRate || '') || 0,
    contractType: CONTRACT_TYPE,
  };
};
