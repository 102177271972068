import { Stack } from '@mui/system';

import { ClubInfos, PracticalInfos, PresidentDetail } from './detail/siege';

export const ClubShowGeneral = () => {
  return (
    <>
      <Stack
        flexDirection={'row'}
        gap={'24px'}
      >
        <PresidentDetail />
        <ClubInfos />
      </Stack>
      <PracticalInfos />
    </>
  );
};
