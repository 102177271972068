import React, { useEffect } from 'react';
import { useAuthActions } from 'src/domains/system/auth/hooks/useAuthActions';
import { useAuthReducer } from 'src/domains/system/auth/hooks/useAuthReducer';

import { AuthContext } from './authContext';

export const ACCESS_TOKEN_STORAGE_KEY = 'accessToken';
export const REFRESH_TOKEN_STORAGE_KEY = 'refreshToken';
export const TWO_FACTOR_IN_PROGRESS = 'twoFactorInProgress';

interface AuthProviderProps {
  children: React.ReactNode;
}

export enum Issuer {
  JWT = 'JWT',
}

export const AuthProvider: React.FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useAuthReducer();
  const { initialize, login, verifyTwoFactor, logout, requestForgotPassword, resetForgotPassword } =
    useAuthActions(dispatch);
  useEffect(() => {
    initialize().catch((err) => {
      console.error(err);
    });
  }, [initialize]);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        issuer: Issuer.JWT,
        login,
        logout,
        verifyTwoFactor,
        requestForgotPassword,
        resetForgotPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
