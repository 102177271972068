import { ListBase, useRecordContext } from 'react-admin';
import { Club } from 'src/admin-api-types';

import { ClubMembershipList } from './ClubMembershipList';
import { ClubMembershipListStats } from './components/ClubMembershipListStats';

export const ClubShowMemberships = () => {
  const record = useRecordContext<Club>();
  const clubId = record?.id;
  const url = `${clubId?.substring(1)}/registrations`;

  return (
    <ListBase resource={url}>
      <ClubMembershipListStats />
      <ClubMembershipList />
    </ListBase>
  );
};
