import { fetchHydra as baseFetchHydra } from '@api-platform/admin';
import remoteAuthProvider from 'src/config/remoteAuthProvider';
import { getHeaders } from 'src/data/utils/getHeaders';
import { isHttpError } from 'src/data/utils/isHttpError';

export const fetchHydra = async (url: URL, options = {}) => {
  try {
    const response = await baseFetchHydra(url, {
      ...options,
      headers: getHeaders({ options }),
    });

    return response;
  } catch (error: unknown) {
    if (isHttpError(error) && error.response && error.response.status === 401) {
      try {
        const errorData = (await error.response.json()) as {
          code: number;
          message: string;
        };
        if (errorData?.code === 401 && errorData?.message === 'Invalid JWT Token') {
          try {
            await remoteAuthProvider.refreshToken();
            return await baseFetchHydra(url, {
              ...options,
              headers: getHeaders({ options }),
            });
          } catch (refreshError) {
            await remoteAuthProvider.logout();
            if (window) {
              window.location.href = '/auth/login';
            }
            console.error('Failed to refresh token:', refreshError);
          }
        }
      } catch (jsonError) {
        console.error('Error parsing error response:', jsonError);
      }
    }

    throw error;
  }
};
