/**
 * Convertit une date sous forme de chaîne en un format d'affichage d'heure UTC.
 *
 * @param {string | null | undefined} createdAt - La date de création sous forme de chaîne (au format ISO).
 * @returns {string} L'heure formatée en UTC, ou '-' si `createdAt` est nul ou indéfini.
 *
 * @example
 * // Pour une date reçue par le back : 2024-12-31T07:30:00+01:00
 *
 * // À Paris (ou n'importe où ailleurs)
 * hourToGridFormat("2024-12-31T07:30:00+01:00");
 * // Retourne "06:30 UTC"
 *
 * // En Martinique (ou n'importe où ailleurs)
 * hourToGridFormat("2024-12-31T07:30:00+01:00");
 * // Retourne également "06:30 UTC"
 *
 * @example
 * hourToGridFormat(null);
 * // Retourne "-"
 */
export const hourToGridFormat = (createdAt: string | null | undefined): string => {
  if (!createdAt) {
    return '-';
  }

  const date = new Date(createdAt);

  const options: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'UTC',
    hour12: false,
  };

  return new Intl.DateTimeFormat('fr-FR', options).format(date);
};
