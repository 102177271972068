export enum FormatOptionValuesEnum {
  monthly = 'monthly',
  fortnightly = 'fortnightly',
  weekly = 'weekly',
}

export enum StatusOptionValuesEnum {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  INITIAL = 'initial',
  USER_ACCEPTED = 'user_accepted',
  USER_REFUSED = 'user_refused',
  PRESIDENT_ACCEPTED = 'president_accepted',
  PRESIDENT_REFUSED = 'president_refused',
  PENDING_SIGN = 'pending_sign',
  VOIDED = 'voided',
  DECLINED = 'declined',
  EXPIRED = 'expired',
}

export enum TypeOptionValuesEnum {
  president = 'president',
  member = 'member',
}
