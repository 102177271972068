/**
 * Checks if a SIRET number is valid according to the Luhn algorithm.
 *
 * @param siret - The SIRET number to validate.
 * @returns A boolean indicating whether the SIRET number is valid.
 * @example
 * isSiretValidLuhn('73282932000074'); // Returns: true
 * isSiretValidLuhn('78467169500087'); // Returns: true
 * isSiretValidLuhn('73282932000075'); // Returns: false
 */
export const isSiretValidLuhn = (siret: string | undefined | null): boolean => {
  if (!siret || siret.length !== 14 || !/^\d{14}$/.test(siret)) {
    return false;
  }

  let sum = 0;

  for (let i = 0; i < siret.length; i++) {
    let intVal = parseInt(siret.charAt(i), 10);

    if (i % 2 === 0) {
      intVal *= 2;
      if (intVal > 9) {
        intVal -= 9;
      }
    }

    sum += intVal;
  }
  return sum % 10 === 0;
};

//! place = Nom du lieu
//! rem président = ownerRate
//! pour dayWeek = jour de la réunion, bien penser à envoyer un nb de 1 à 7
//! date de la premiere reunion = startEvent
//! pour le legalForm : il faudra faire un GET, mais en attendant en dur avec 0190bfbc-b726-7b21-94de-5dc24b39778f /
//! rcs = companyRegister
//! civility = civilité
//! ajouter "contractType": "club_president" au json (get optionnel)
