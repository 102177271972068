import EuroIcon from '@mui/icons-material/Euro';
import { Stack } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import { contractContext } from 'src/domains/club/contract/context';
import { SectionLayout } from 'src/domains/club/contract/layouts/SectionLayout';
import { countryOptions } from 'src/domains/club/contract/steps/stepTwo/utils/';
import { CustomInputLabel } from 'src/domains/club/members/detail/fields/CustomInputLabel';
import { CustomSelectLabel } from 'src/domains/club/members/detail/fields/CustomSelectLabel';
import { tokens } from 'src/locales/tokens';

export const CompanyInfos: React.FC = () => {
  const { secondStep, setSecondStep } = React.useContext(contractContext);

  return (
    <SectionLayout title={t(tokens.domains.contract.stepTwo.companyInfos.title)}>
      <Stack flexDirection={'row'}>
        <Stack
          flexGrow="1"
          padding={'0px 24px 30px 24px'}
          spacing={4}
        >
          <Stack
            direction={'row'}
            spacing={3}
            gap="55px"
            width={'calc(50% - 30px)'}
          >
            <CustomSelectLabel
              source="legalForm"
              name="legalForm"
              label={`${t(tokens.domains.contract.stepTwo.companyInfos.legalForm.label)} *`}
              dataSource="legal_forms"
            />
          </Stack>
          <Stack
            direction={'row'}
            spacing={3}
            gap="55px"
          >
            <CustomInputLabel
              source="name"
              name="name"
              label={`${t(tokens.domains.contract.stepTwo.companyInfos.name.label)} *`}
              placeholder={t(tokens.domains.contract.stepTwo.companyInfos.name.placeholder)}
            />
            <CustomInputLabel
              source="brand"
              name="brand"
              label={`${t(tokens.domains.contract.stepTwo.companyInfos.brand.label)}`}
              placeholder={t(tokens.domains.contract.stepTwo.companyInfos.brand.placeholder)}
            />
          </Stack>
          <Stack
            direction={'row'}
            spacing={3}
            gap="55px"
          >
            <CustomInputLabel
              source="companyRegister"
              name="companyRegister"
              label={`${t(tokens.domains.contract.stepTwo.companyInfos.rcs.label)} *`}
              placeholder={t(tokens.domains.contract.stepTwo.companyInfos.rcs.placeholder)}
            />
            <CustomInputLabel
              source="activity"
              name="activity"
              label={`${t(tokens.domains.contract.stepTwo.companyInfos.activity.label)} *`}
              placeholder={t(tokens.domains.contract.stepTwo.companyInfos.activity.placeholder)}
            />
          </Stack>
          <Stack
            direction={'row'}
            spacing={3}
            gap="55px"
          >
            <CustomInputLabel
              source="street"
              name="street"
              label={`${t(tokens.domains.contract.stepTwo.companyInfos.compagnyAddress.label)} *`}
              placeholder={t(
                tokens.domains.contract.stepTwo.companyInfos.compagnyAddress.placeholder
              )}
            />
            <CustomInputLabel
              source="zipCode"
              name="zipCode"
              label={`${t(
                tokens.domains.contract.stepTwo.companyInfos.compagnyPostalCode.label
              )} *`}
              placeholder={t(
                tokens.domains.contract.stepTwo.companyInfos.compagnyPostalCode.placeholder
              )}
            />
          </Stack>
          <Stack
            direction={'row'}
            spacing={3}
            gap="55px"
          >
            <CustomInputLabel
              source="city"
              name="city"
              label={`${t(tokens.domains.contract.stepTwo.companyInfos.compagnyCity.label)} *`}
              placeholder={t(tokens.domains.contract.stepTwo.companyInfos.compagnyCity.placeholder)}
            />
            <CustomSelectLabel
              source="country"
              name="country"
              label={`${t(tokens.domains.contract.stepTwo.companyInfos.compagnyCountry.label)} *`}
              choices={countryOptions}
              firstStep={secondStep}
              setFirstStep={setSecondStep}
              disabled
            />
          </Stack>
          <Stack
            direction={'row'}
            spacing={3}
            gap="55px"
          >
            <CustomInputLabel
              source="size"
              name="size"
              label={`${t(tokens.domains.contract.stepTwo.companyInfos.compagnySize.label)} *`}
              placeholder={t(tokens.domains.contract.stepTwo.companyInfos.compagnySize.placeholder)}
              tooltipTitle={t(tokens.domains.contract.stepTwo.companyInfos.compagnySize.tooltip)}
            />
            <CustomInputLabel
              source="capital"
              name="capital"
              label={`${t(tokens.domains.contract.stepTwo.companyInfos.compagnyCapital.label)} *`}
              placeholder={t(
                tokens.domains.contract.stepTwo.companyInfos.compagnyCapital.placeholder
              )}
              endAdornment={<EuroIcon />}
            />
          </Stack>
        </Stack>
      </Stack>
    </SectionLayout>
  );
};
