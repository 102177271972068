import { Card, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import { FunctionField, Identifier, RaRecord, TextField, useShowContext } from 'react-admin';
import { Club } from 'src/admin-api-types';
import { TopLabelField } from 'src/components/react-admin/core/fields';
import { CardTitle } from 'src/domains/club/members/detail/common/CardTitle';
import { tokens } from 'src/locales/tokens';
import { formatContent } from 'src/utils/formatContent';
import { formatNbToDay } from 'src/utils/formatNbToDay';
import { hourToGridFormat } from 'src/utils/hourToGridFormat';

import { PracticalInfosSkeleton } from '../../skeletons';

export const PracticalInfos: React.FC = () => {
  const { isFetching } = useShowContext<RaRecord<Identifier>>();
  if (isFetching) return <PracticalInfosSkeleton />;
  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexBasis: '50%',
      }}
    >
      <CardTitle text={t(tokens.domains.clubs.detail.practicalInfoCardtitle)} />
      <Stack flexDirection={'row'}>
        <Stack
          flexGrow="1"
          padding={'0px 24px 30px 24px'}
          spacing={4}
          flexBasis={'50%'}
        >
          <TopLabelField label={t(tokens.domains.members.detail.label.clubAddress)}>
            <TextField source={'postalAddress.fullAddress'} />
          </TopLabelField>
          <TopLabelField label={t(tokens.domains.members.detail.label.meetings)}>
            <FunctionField
              render={(record) => {
                if (!record.clubSetting) return null;
                return (
                  <Typography variant="body2">
                    {`Les ${formatNbToDay(record.clubSetting.dayWeek)} ${formatContent(
                      record.clubSetting.format
                    )} de ${hourToGridFormat(record.clubSetting.openingTime)} à ${hourToGridFormat(
                      record.clubSetting.closingTime
                    )}`}
                  </Typography>
                );
              }}
            />
          </TopLabelField>
        </Stack>
      </Stack>
    </Card>
  );
};
