import { Box, Typography } from '@mui/material';
import React from 'react';
import { useRecordContext } from 'react-admin';
import { Organization } from 'src/admin-api-types';

interface ConcatAddressProps {
  label: string;
}

export const ConcatAddressLabel: React.FC<ConcatAddressProps> = ({ label }) => {
  const record = useRecordContext<Organization>();

  if (!record || !record.postalAddress) {
    return null;
  }

  const { street, zipCode, city } = record.postalAddress;

  const addressParts = [street, zipCode].filter(Boolean);
  const address = addressParts.length > 0 ? addressParts.join(', ') : undefined;
  const cityValue = city || undefined;

  const hasAddress = address || cityValue;

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <Typography
        variant={'body2'}
        fontWeight={600}
        gutterBottom
      >
        {label}
      </Typography>
      {hasAddress ? (
        <>
          {address && <Typography variant="body2">{address}</Typography>}
          {cityValue && <Typography variant="body2">{cityValue}</Typography>}
        </>
      ) : (
        <Typography variant="body2">-</Typography>
      )}
    </Box>
  );
};
