import { Typography } from '@mui/material';
import Stack from '@mui/system/Stack';
import { t } from 'i18next';
import { DateField, FunctionField, NumberField, ShowButton, TextField } from 'react-admin';
import { Club } from 'src/admin-api-types';
import { ColumnHeaderLabel } from 'src/common/enums/react-admin';
import { DatagridLayout } from 'src/common/layouts/react-admin';
import { ClubSortByEnum } from 'src/data/enum/local/orderFilterEnum';
import { StatusField } from 'src/domains/club/clubs/components/react-admin/fields';
import { useAuth } from 'src/domains/system/auth/hooks/useAuth';
import { tokens } from 'src/locales/tokens';
import { UserRole } from 'src/types/enums/user-roles-enum';

import { ClubSourceEnum } from '../../enums/club-source-enum';

interface CenteredCellProps {
  children: React.ReactNode;
}

const CenteredCell: React.FC<CenteredCellProps> = ({ children }) => (
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
    {children}
  </div>
);

export const ClubGrid = () => {
  const { user } = useAuth();
  const { roles } = user || {};
  const isAdmin = roles
    ? roles.includes(UserRole.ADMIN) || roles.includes(UserRole.SUPER_ADMIN)
    : false;

  return (
    <DatagridLayout content={t(tokens.domains.clubs.list.grid.noResult)}>
      <TextField
        source={ClubSourceEnum.NAME}
        label={ColumnHeaderLabel.clubName}
      />

      <FunctionField
        label={ColumnHeaderLabel.president}
        sortBy={ClubSortByEnum.ORDER_BY_PRESIDENT_NAME}
        render={(record: Club) =>
          record.president ? (
            <Stack>
              <TextField source={'president.fullName'} />
              <TextField
                source={'president.email'}
                variant={'caption'}
              />
              <TextField
                source={'president.phone'}
                variant={'caption'}
              />
            </Stack>
          ) : (
            <>-</>
          )
        }
      ></FunctionField>
      <FunctionField
        label={ColumnHeaderLabel.agency}
        render={(record: Club) => {
          const parentAgencyName: string | undefined = record?.parentAgency?.name;
          const agencyName: string | undefined = record?.agency?.name;
          if (isAdmin && parentAgencyName) {
            return (
              <Stack>
                <Typography>{parentAgencyName}</Typography>
                <Typography sx={{ fontSize: '12px', color: 'text.secondary' }}>
                  {agencyName}
                </Typography>
              </Stack>
            );
          }
          return agencyName || '-';
        }}
      />
      <TextField
        source={ClubSourceEnum.FORMAT}
        sortBy={ClubSortByEnum.ORDER_BY_FORMAT}
        label={ColumnHeaderLabel.format}
      />
      <TextField
        label={ColumnHeaderLabel.department}
        source={ClubSourceEnum.DEPARTMENT}
        sortBy={ClubSortByEnum.ORDER_BY_DEPARTMENT_CODE}
      />
      <FunctionField
        label={ColumnHeaderLabel.status}
        sortBy={ClubSortByEnum.ORDER_BY_STATUS}
        render={() => (
          <CenteredCell>
            <StatusField source={ClubSourceEnum.STATUS} />
          </CenteredCell>
        )}
      />

      <FunctionField
        label={ColumnHeaderLabel.members}
        sortBy={ClubSortByEnum.ORDER_BY_COUNT_ACTIVE_MEMBERS}
        render={() => (
          <CenteredCell>
            <NumberField source={ClubSourceEnum.COUNT_ACTIVE_MEMBERS} />
          </CenteredCell>
        )}
      />
      <DateField
        source={ClubSourceEnum.CREATED_AT}
        label={ColumnHeaderLabel.createdAt}
      />
      <FunctionField
        label={ColumnHeaderLabel.action}
        render={(record: Club) => (record.president ? <ShowButton /> : <></>)}
      />
    </DatagridLayout>
  );
};
